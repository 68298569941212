.Header {
  text-align: left;
  width: 100vw;
  overflow: hidden;
  background: #fff;
  padding: 5px;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,.07);
  position: fixed;
  justify-content: space-between;
  align-items: center;
  padding: 0 30;
  display: flex;
}

#codewithlogo {
	width: 150px;
	margin-left: 20px;
	float: left;
}

#button-sign-in {
	float: right;
	margin: -10px;
	border-radius: 7px;
	font-family: monospace;
}

#button-sign-out {
	float: right;
	text-align: center;
	max-width: 100px;
	border-radius: 7px;
	margin-right: 20px;
}

.displayPhoto { 	
    float: left;
}

.displayName {
	margin-top: 15px;
	margin-left: 5px;
	z-index: 10000;
	float: right;
	font-family: monospace;
    font-weight: bold;
    font-size: large;
    color: #141c3a;
}

@media only screen and (max-width: 900px) {
  .displayName {
    display: none;
  }
}

#loggedInUserImage {
	border-radius: 50%;
	max-height: 50px;
	max-width: 50px;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,.07);
}

.loggedInUserDetails {

}

.btnClrd {
  cursor: pointer;
  margin: 5px;
  border-radius: 5px;
  text-decoration: none;
  padding: 5px;
  transition: .3s;
  -webkit-transition: .3s;
  -moz-transition: .3s;
  -o-transition: .3s;
  display: inline-block;
}

.btnClrd:hover {
  cursor: url(http://cur.cursors-4u.net/symbols/sym-1/sym46.cur), auto;
}

.purple {
  color: #9b59b6;
  border: 2px #9b59b6 solid;
}

.purple:hover {
  color: #fff;
  background-color: #9b59b6;
}

.orange {
  color: #ff8e8e;
  border: 2px #ff8e8e solid;
}

.orange:hover {
  color: #fff;
  background-color: #ff8e8e;
}

.signoutBtn {
  margin-right: 20px;
  text-align: center;
  font-family: monospace;
  font-weight: bold;
  font-size: large;
}