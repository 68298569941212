.Resume {
  text-align: center;
  width: 100%;
  overflow: hidden;
}

*, *:before, *:after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

#portraitface {
  position: fixed;
  margin: 0 auto;
  margin-top: 70px;
  -webkit-animation: rotation 2s infinite linear;
  animation: rotation .25s infinite linear alternate;
}

.tiwari {
  position: fixed;
  left: 30%;
  bottom: 223px;
}

#spacey-left {
  display: none;
}
#underdev {
  position: fixed;
  margin: 0 auto;
  left: 35%;
  margin-top: 20px;
  height: 50px;
  z-index: 100;
}

#spacey {
  position: fixed;
  left: 30%;
  bottom: 223px;
}

.chat {
  position: fixed;
  height: 200px;
  display: none;
}

.stars {
  width: 100%;
  height: 100%;
  perspective: 10px;
  -webkit-perspective: 10px;
  -moz-perspective: 10px;
  -ms-perspective: 10px;

  transform-origin: 10% 20%;
  -webkit-transform-origin: 10% 20%;
  -moz-transform-origin: 10% 20%;
  -ms-transform-origin: 10% 20%;
}

.staticstar {
  z-index: -1000;
  position: fixed;
  height: 30px;
}

#portrait-footer {
  opacity: 1;
  z-index: 1000;
  position: fixed;
  bottom: 0;
  left: 0;
  height: 150px;
}

/* Wrapper */
.icon-button {
  background-color: white;
  border-radius: 3.6rem;
  cursor: pointer;
  display: block;
  font-size: 2.0rem;
  height: 3.6rem;
  line-height: 3.6rem;
  margin: 0 5px;
  position: relative;
  text-align: center;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  width: 3.6rem;
}

/* Circle */
.icon-button span {
  border-radius: 0;
  display: block;
  height: 0;
  left: 50%;
  margin: 0;
  position: absolute;
  top: 50%;
  -webkit-transition: all 0.3s;
     -moz-transition: all 0.3s;
       -o-transition: all 0.3s;
          transition: all 0.3s;
  width: 0;
}
.icon-button:hover span {
  width: 3.6rem;
  height: 3.6rem;
  border-radius: 3.6rem;
  margin: -1.8rem;
}

.facebook span {
  background-color: #3B5998;
}

.github span {
  background-color: #3B5998;
}

.code span {
  background-color: #db5a3c;
}

.linkedin span {
  background-color: #db5a3c;
}

/* Icons */
.icon-button i {
  background: none;
  color: white;
  height: 3.6rem;
  left: 0;
  line-height: 3.6rem;
  position: absolute;
  top: 0;
  -webkit-transition: all 0.3s;
     -moz-transition: all 0.3s;
       -o-transition: all 0.3s;
          transition: all 0.3s;
  width: 3.6rem;
  z-index: 10;
}

.icon-button .icon-facebook {
  color: #000;
}

.icon-button .icon-code {
  color: #000;
}

.icon-button .icon-github {
  color: #000;
}

.icon-button .icon-linkedin {
  color: #000;
}

.icon-button:hover .icon-facebook,

.icon-button:hover .icon-linkedin,

.icon-button:hover .icon-code,

.icon-button:hover .icon-github,

.social {
  margin-top: 0px;
}

.hlayer1 {
  bottom: 0px;
  position: fixed;
  z-index: -100;
  height: 100%;
  width: 20000px;
  background: #060b14;

}

.hlayer2 {
  bottom: 20px;
  position: fixed;
}

.hlayer3 {
  bottom: 0;
  width: 20000px;
  height: 100%;

  background-position: bottom center;
  background-size: auto;
  background-repeat: repeat-x;
  position: fixed;
  z-index: 1;
}

.hlayer7 {
  bottom: 200px;
  width: 500px;
  height: 4000px;
  background-position: bottom center;
  background-size: auto;
  background-repeat: repeat-y;
  position: fixed;
  z-index: 0;
}

.hlayer4 {
  position: fixed;
  bottom: 0;
  z-index: 0;
}

.hlayer5 {
  bottom: 150px;
  overflow: hidden;
  background-position: bottom center;
  background-size: auto;
  background-repeat: no-repeat;
  position: fixed;
  z-index: -2;
}

.hlayer6 {
  bottom: 200px;
  position: fixed;
  z-index: -1;
}
.control-text{
    position: absolute;
    z-index: 20000;
    bottom: 14px;
    left: 35%;
    font-family: 'Fredoka One', cursive;
    color: #fff;
    font-size: 30px;
    animation: blinker 1s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

@keyframes moveX{
  from{background-position-x:0px;}
  to{background-position-x:-1818px;}
}