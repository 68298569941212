.CodeWith {
  text-align: left;

}
.content {
	background: #fafafa;
	height: 100vh;
}
.blocks {
	margin-left: 10px;
    display: block;
    padding: 10px;
}
.Leftcontent {
	float: left;
}
.Rightcontent {
	float: right;
    font-family: monospace;
    font-weight: bold;
    top: 100px;
    font-size: -webkit-xxx-large;
    position: relative;
}

.laptop {
	margin-left: 10px;
    display: block;
    max-width: 70%;
    padding: 10px;
}
.main-app {
	overflow: auto;
}