.staticBg {
	background: #fafafa;
	position: relative;
	display: block;
	z-index: -5;
	margin-top: 60px;
}

#laptopImg {
	max-height: 900px;
}