.IntroCard {
	top: 100px;
}

.CPCard {
	top: 500px;
}

.MainContentCard {
	background: #fff;
	border-radius: 7px;
	max-width: 500px;
	position: absolute;
	z-index: -1;
	left: 900px;
	right: 0;
	margin: auto;
	padding: 15px;
    box-shadow: 0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22);
}

@media only screen and (min-height: 900px) {
  .MainContentCard {
    position: fixed;
  }
}

@media only screen and (max-width: 1400px) {
	.CPCard {
		top: 650;
	}
	.IntroCard {
		top: 75px;
	}
	.MainContentCard {
		width: 500px;
		max-width: 70vw;
		left:0;
	}
}

.MainContentCardTitle {
    color: #141c3a;
	padding: 10px;
}

.MainContentCardBody {
	color: #666;
	font-family: montserrat;
	font-weight: bold;
	padding: 10px;
	text-align: justify;
}

.lightText {
	opacity: 0.8;
}

.lighterText {
	opacity: 0.5;
}
hr.coloredBar {
	border: 1px solid #ff8e8e;
	align-self: center;
	opacity: 0.4;
	margin-top: 5px;
}

hr.lightgreyBar {
	border: 1px solid #ddd;
	align-self: center;
	opacity: 0.4;
	margin-top: 5px;
}

.centerAlign {
	text-align: center;
}

.inlineItem {
	display: inline;
}

.PriceAndDuration {
	font-weight: lighter;
	margin: 5px;
	padding: 5px;	
}

.red {
	color: #D40000;
}

.green {
	color: #008000;
}

.dot {
	height: 10px;
}