body {
  font-family: 'Roboto', 'Helvetica';
  touch-action: none; 
}
.loader {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 9999999999;
  background: #ccc;
}
.trails1 path {
  stroke-dasharray: 30.522;
  stroke-dashoffset: 30.522;
  -webkit-animation: trails-anim 0.5s infinite;
          animation: trails-anim 0.5s infinite;
}

.trails2 path {
  stroke-dasharray: 30.522;
  stroke-dashoffset: 30.522;
  -webkit-animation: trails-anim1 0.4s infinite;
          animation: trails-anim1 0.4s infinite;
}

.loadingsvg {
  -webkit-animation: shake 0.5s infinite;
      animation: shake 0.5s infinite; 
}


@-webkit-keyframes trails-anim {
  43% {
    stroke-dashoffset: 0;
  }
  100% {
  	stroke-dashoffset: 0;	
  }
}

@keyframes trails-anim1 {
  43% {
    stroke-dashoffset: 0;
  }
  100% {
  	stroke-dashoffset: 0;	
  }
}

@-webkit-keyframes trails-anim1 {
  60% {
    stroke-dashoffset: 0;
  }
  100% {
  	stroke-dashoffset: 0;	
  }
}

@keyframes trails-anim1 {
  60% {
    stroke-dashoffset: 0;
  }
  100% {
  	stroke-dashoffset: 0;	
  }
}

@keyframes shake {
  10%, 90% {
    transform: translate3d(-1px, 1px, 1px);
  }
  
  20%, 80% {
    transform: translate3d(2px, -1px, -2px);
  }

  30%, 50%, 70% {
    transform: translate3d(-4px, 2px, 4px);
  }

  40%, 60% {
    transform: translate3d(4px, -3px, -4px);
  }
  100% {
  	transform: translate3d(2px, 3px, -2px);	
  }
}