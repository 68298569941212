body {
  font-family: 'Roboto', 'Helvetica';
  touch-action: none; 
}
.loader {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 9999999999;
  background: #ccc;
}
.trails1 path {
  stroke-dasharray: 30.522;
  stroke-dashoffset: 30.522;
  -webkit-animation: trails-anim 0.5s infinite;
          animation: trails-anim 0.5s infinite;
}

.trails2 path {
  stroke-dasharray: 30.522;
  stroke-dashoffset: 30.522;
  -webkit-animation: trails-anim1 0.4s infinite;
          animation: trails-anim1 0.4s infinite;
}

.loadingsvg {
  -webkit-animation: shake 0.5s infinite;
      animation: shake 0.5s infinite; 
}


@-webkit-keyframes trails-anim {
  43% {
    stroke-dashoffset: 0;
  }
  100% {
  	stroke-dashoffset: 0;	
  }
}

@keyframes trails-anim1 {
  43% {
    stroke-dashoffset: 0;
  }
  100% {
  	stroke-dashoffset: 0;	
  }
}

@-webkit-keyframes trails-anim1 {
  60% {
    stroke-dashoffset: 0;
  }
  100% {
  	stroke-dashoffset: 0;	
  }
}

@keyframes trails-anim1 {
  60% {
    stroke-dashoffset: 0;
  }
  100% {
  	stroke-dashoffset: 0;	
  }
}

@-webkit-keyframes shake {
  10%, 90% {
    -webkit-transform: translate3d(-1px, 1px, 1px);
            transform: translate3d(-1px, 1px, 1px);
  }
  
  20%, 80% {
    -webkit-transform: translate3d(2px, -1px, -2px);
            transform: translate3d(2px, -1px, -2px);
  }

  30%, 50%, 70% {
    -webkit-transform: translate3d(-4px, 2px, 4px);
            transform: translate3d(-4px, 2px, 4px);
  }

  40%, 60% {
    -webkit-transform: translate3d(4px, -3px, -4px);
            transform: translate3d(4px, -3px, -4px);
  }
  100% {
  	-webkit-transform: translate3d(2px, 3px, -2px);
  	        transform: translate3d(2px, 3px, -2px);	
  }
}

@keyframes shake {
  10%, 90% {
    -webkit-transform: translate3d(-1px, 1px, 1px);
            transform: translate3d(-1px, 1px, 1px);
  }
  
  20%, 80% {
    -webkit-transform: translate3d(2px, -1px, -2px);
            transform: translate3d(2px, -1px, -2px);
  }

  30%, 50%, 70% {
    -webkit-transform: translate3d(-4px, 2px, 4px);
            transform: translate3d(-4px, 2px, 4px);
  }

  40%, 60% {
    -webkit-transform: translate3d(4px, -3px, -4px);
            transform: translate3d(4px, -3px, -4px);
  }
  100% {
  	-webkit-transform: translate3d(2px, 3px, -2px);
  	        transform: translate3d(2px, 3px, -2px);	
  }
}
.App {
  text-align: center;
  width: 100%;
}
.Resume {
  text-align: center;
  width: 100%;
  overflow: hidden;
}

*, *:before, *:after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

#portraitface {
  position: fixed;
  margin: 0 auto;
  margin-top: 70px;
  -webkit-animation: rotation 2s infinite linear;
  animation: rotation .25s infinite linear alternate;
}

.tiwari {
  position: fixed;
  left: 30%;
  bottom: 223px;
}

#spacey-left {
  display: none;
}
#underdev {
  position: fixed;
  margin: 0 auto;
  left: 35%;
  margin-top: 20px;
  height: 50px;
  z-index: 100;
}

#spacey {
  position: fixed;
  left: 30%;
  bottom: 223px;
}

.chat {
  position: fixed;
  height: 200px;
  display: none;
}

.stars {
  width: 100%;
  height: 100%;
  perspective: 10px;
  -webkit-perspective: 10px;
  -moz-perspective: 10px;
  -ms-perspective: 10px;

  transform-origin: 10% 20%;
  -webkit-transform-origin: 10% 20%;
  -moz-transform-origin: 10% 20%;
  -ms-transform-origin: 10% 20%;
}

.staticstar {
  z-index: -1000;
  position: fixed;
  height: 30px;
}

#portrait-footer {
  opacity: 1;
  z-index: 1000;
  position: fixed;
  bottom: 0;
  left: 0;
  height: 150px;
}

/* Wrapper */
.icon-button {
  background-color: white;
  border-radius: 3.6rem;
  cursor: pointer;
  display: block;
  font-size: 2.0rem;
  height: 3.6rem;
  line-height: 3.6rem;
  margin: 0 5px;
  position: relative;
  text-align: center;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  width: 3.6rem;
}

/* Circle */
.icon-button span {
  border-radius: 0;
  display: block;
  height: 0;
  left: 50%;
  margin: 0;
  position: absolute;
  top: 50%;
  transition: all 0.3s;
  width: 0;
}
.icon-button:hover span {
  width: 3.6rem;
  height: 3.6rem;
  border-radius: 3.6rem;
  margin: -1.8rem;
}

.facebook span {
  background-color: #3B5998;
}

.github span {
  background-color: #3B5998;
}

.code span {
  background-color: #db5a3c;
}

.linkedin span {
  background-color: #db5a3c;
}

/* Icons */
.icon-button i {
  background: none;
  color: white;
  height: 3.6rem;
  left: 0;
  line-height: 3.6rem;
  position: absolute;
  top: 0;
  transition: all 0.3s;
  width: 3.6rem;
  z-index: 10;
}

.icon-button .icon-facebook {
  color: #000;
}

.icon-button .icon-code {
  color: #000;
}

.icon-button .icon-github {
  color: #000;
}

.icon-button .icon-linkedin {
  color: #000;
}

.icon-button:hover .icon-facebook,

.icon-button:hover .icon-linkedin,

.icon-button:hover .icon-code,

.icon-button:hover .icon-github,

.social {
  margin-top: 0px;
}

.hlayer1 {
  bottom: 0px;
  position: fixed;
  z-index: -100;
  height: 100%;
  width: 20000px;
  background: #060b14;

}

.hlayer2 {
  bottom: 20px;
  position: fixed;
}

.hlayer3 {
  bottom: 0;
  width: 20000px;
  height: 100%;

  background-position: bottom center;
  background-size: auto;
  background-repeat: repeat-x;
  position: fixed;
  z-index: 1;
}

.hlayer7 {
  bottom: 200px;
  width: 500px;
  height: 4000px;
  background-position: bottom center;
  background-size: auto;
  background-repeat: repeat-y;
  position: fixed;
  z-index: 0;
}

.hlayer4 {
  position: fixed;
  bottom: 0;
  z-index: 0;
}

.hlayer5 {
  bottom: 150px;
  overflow: hidden;
  background-position: bottom center;
  background-size: auto;
  background-repeat: no-repeat;
  position: fixed;
  z-index: -2;
}

.hlayer6 {
  bottom: 200px;
  position: fixed;
  z-index: -1;
}
.control-text{
    position: absolute;
    z-index: 20000;
    bottom: 14px;
    left: 35%;
    font-family: 'Fredoka One', cursive;
    color: #fff;
    font-size: 30px;
    -webkit-animation: blinker 1s linear infinite;
            animation: blinker 1s linear infinite;
}

@-webkit-keyframes blinker {
  50% {
    opacity: 0;
  }
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

@-webkit-keyframes moveX{
  from{background-position-x:0px;}
  to{background-position-x:-1818px;}
}

@keyframes moveX{
  from{background-position-x:0px;}
  to{background-position-x:-1818px;}
}
.CodeWith {
  text-align: left;

}
.content {
	background: #fafafa;
	height: 100vh;
}
.blocks {
	margin-left: 10px;
    display: block;
    padding: 10px;
}
.Leftcontent {
	float: left;
}
.Rightcontent {
	float: right;
    font-family: monospace;
    font-weight: bold;
    top: 100px;
    font-size: -webkit-xxx-large;
    position: relative;
}

.laptop {
	margin-left: 10px;
    display: block;
    max-width: 70%;
    padding: 10px;
}
.main-app {
	overflow: auto;
}
.Header {
  text-align: left;
  width: 100vw;
  overflow: hidden;
  background: #fff;
  padding: 5px;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,.07);
  position: fixed;
  justify-content: space-between;
  align-items: center;
  padding: 0 30;
  display: flex;
}

#codewithlogo {
	width: 150px;
	margin-left: 20px;
	float: left;
}

#button-sign-in {
	float: right;
	margin: -10px;
	border-radius: 7px;
	font-family: monospace;
}

#button-sign-out {
	float: right;
	text-align: center;
	max-width: 100px;
	border-radius: 7px;
	margin-right: 20px;
}

.displayPhoto { 	
    float: left;
}

.displayName {
	margin-top: 15px;
	margin-left: 5px;
	z-index: 10000;
	float: right;
	font-family: monospace;
    font-weight: bold;
    font-size: large;
    color: #141c3a;
}

@media only screen and (max-width: 900px) {
  .displayName {
    display: none;
  }
}

#loggedInUserImage {
	border-radius: 50%;
	max-height: 50px;
	max-width: 50px;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,.07);
}

.loggedInUserDetails {

}

.btnClrd {
  cursor: pointer;
  margin: 5px;
  border-radius: 5px;
  text-decoration: none;
  padding: 5px;
  transition: .3s;
  -webkit-transition: .3s;
  -moz-transition: .3s;
  -o-transition: .3s;
  display: inline-block;
}

.btnClrd:hover {
  cursor: url(http://cur.cursors-4u.net/symbols/sym-1/sym46.cur), auto;
}

.purple {
  color: #9b59b6;
  border: 2px #9b59b6 solid;
}

.purple:hover {
  color: #fff;
  background-color: #9b59b6;
}

.orange {
  color: #ff8e8e;
  border: 2px #ff8e8e solid;
}

.orange:hover {
  color: #fff;
  background-color: #ff8e8e;
}

.signoutBtn {
  margin-right: 20px;
  text-align: center;
  font-family: monospace;
  font-weight: bold;
  font-size: large;
}
.staticBg {
	background: #fafafa;
	position: relative;
	display: block;
	z-index: -5;
	margin-top: 60px;
}

#laptopImg {
	max-height: 900px;
}
.IntroCard {
	top: 100px;
}

.CPCard {
	top: 500px;
}

.MainContentCard {
	background: #fff;
	border-radius: 7px;
	max-width: 500px;
	position: absolute;
	z-index: -1;
	left: 900px;
	right: 0;
	margin: auto;
	padding: 15px;
    box-shadow: 0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22);
}

@media only screen and (min-height: 900px) {
  .MainContentCard {
    position: fixed;
  }
}

@media only screen and (max-width: 1400px) {
	.CPCard {
		top: 650;
	}
	.IntroCard {
		top: 75px;
	}
	.MainContentCard {
		width: 500px;
		max-width: 70vw;
		left:0;
	}
}

.MainContentCardTitle {
    color: #141c3a;
	padding: 10px;
}

.MainContentCardBody {
	color: #666;
	font-family: montserrat;
	font-weight: bold;
	padding: 10px;
	text-align: justify;
}

.lightText {
	opacity: 0.8;
}

.lighterText {
	opacity: 0.5;
}
hr.coloredBar {
	border: 1px solid #ff8e8e;
	align-self: center;
	opacity: 0.4;
	margin-top: 5px;
}

hr.lightgreyBar {
	border: 1px solid #ddd;
	align-self: center;
	opacity: 0.4;
	margin-top: 5px;
}

.centerAlign {
	text-align: center;
}

.inlineItem {
	display: inline;
}

.PriceAndDuration {
	font-weight: lighter;
	margin: 5px;
	padding: 5px;	
}

.red {
	color: #D40000;
}

.green {
	color: #008000;
}

.dot {
	height: 10px;
}
